import React from 'react'



const Root: React.FC = () => <>
<div>
    <h2>
        This is the root
    </h2>
</div>
</>


export default Root;